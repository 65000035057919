<template>
  <div></div>
</template>
<script>
import 'jspdf-autotable';
import FileSaver from 'file-saver';
import JsZip from 'jszip';
import FormatUtils from '@/utils/FormatUtils';
import PaymentType from "@/utils/PaymentType";

export default {
    name: 'CsvUtils',
    mixins: [FormatUtils],
    data() {
        return {
            formatCsv: '.csv',
            companyInfoCsv: {
                companyName: 'ABN AMRO Bank N.V.',
                contactName: 'Name of the contact',
                homePhone: '06-1234567890',
                mobilePhone: '+31 612345678',
                email: 'name.lastname@nl.abnamro.com',
                iban: 'NL68ABNA3675462129',
                countryCode: 'NLABNA123',
                otherInfo: 'Other Info',
            },
            activeWorkerHeader: ['id', 'creationDate', 'company', 'userType', 'firstname', 'lastname', 'email', 'iban', 'balance', 'onboarded', 'status'],
            inactiveWorkerHeader: ['id', 'creationDate', 'company', 'userType', 'firstname', 'lastname', 'email', 'iban', 'status'],
            transactionHeader: ['User ID', 'Ref ID', 'Company', 'Name', 'Description', 'Iban', 'Creation Date', 'Update Date','Exec Date', 'Type', 'Amount', 'Status', 'Reason Status'],
            errorPisHeader: ['User ID', 'company', 'name', 'Creation Date', 'Update Date', 'Type', 'Amount', 'Status', 'Tries'],
            errorServerHeader: ['ErrorID', 'company', 'type', 'Description', 'HTTP Status', 'Date'],
            csvMimeType: 'text/csv;encoding:utf-8',
            workerProfileHeaderCsv: ['User ID', 'Company ', 'Firstname ', 'Lastname ', 'Email ', 'Phone Number ', 'Iban ',],
            workerGigHeaderCsv: ['Id', 'Update Time', 'Start Time', 'End Time', 'Hour Rate', 'Gross Income', 'Provisional Income', 'Status',],
            walletGigHeaderCsv: ['Date', 'Description', 'Amount'],
            payrollHeaderCSV: ['Payroll Id', 'User Id', 'Name', 'Date', 'Period', 'Net Income', 'Incoming', 'Outgoing', 'Balance', 'Status'],
            balanceAndDepositHeaderCSV: ['Deposit Id', 'Date', 'Description', 'Type', 'Amount', 'Status'],
            notificationHeaderCSV: ['Notification Id', 'User Id', 'Platform', 'Type', 'Title'],
            invoiceHeaderCSV: ['P. user Id', 'Name', 'Invoice Number', 'Invoice Date', 'Due Date','Pay Date', 'Amount', 'Fee', 'Status', 'Company', 'Employer'],
        };
    },
    methods: {
        selectionToActiveWorkers(selection) {
            return selection.map(worker => ([
                worker.id.toString(),
                new Date(worker.creationDate).toLocaleString('nl-NL'),
                worker.company,
                worker.userType,
                worker.firstname,
                worker.lastname,
                worker.email,
                worker.iban,
                `€ ${worker.balance}`,
                worker.onBoarded ? 'Yes' : 'No',
                worker.status
            ]));
        },
        selectionToInactiveWorkers(selection) {
            return selection.map(worker => ([
                worker.id.toString(),
                new Date(worker.creationDate).toLocaleString('nl-NL'),
                worker.company,
                worker.userType,
                worker.firstname,
                worker.lastname,
                worker.email,
                worker.iban,
                `${worker.status}`,
            ]));
        },
        selectionToTransactions(selection, getCompanyName, getStatus) {
            return selection.map(transaction => ([
                transaction.uid.toString(),
                transaction.refid ? transaction.refid.toString() : '-',
                getCompanyName(transaction.companyId),
                transaction.counterpartyName,
                transaction.description ? transaction.description.toString() : 'No Description',
                transaction.counterpartyIban,
                this.getDateByTransaction(transaction.creationDate),
                this.getDateByTransaction(transaction.updateDate),
                this.getDateByTransaction(transaction.executionDate),
                this.$t(PaymentType[transaction.paymentType].name),
                `${transaction.amount || transaction.totalAmount}`,
                `${getStatus(transaction.status)}`,
                `${transaction.reasonNote}`,
            ]));
        },
        selectionToErrorPis(selection, getCompanyName, getErrorType, getErrorStatus) {
            return selection.map(transactionError => [
                transactionError.userId.toString(),
                getCompanyName(transactionError.companyId),
                transactionError.workerName,
                transactionError.creationDate,
                transactionError.updateDate,
                getErrorType(transactionError.type),
                `${this.getAmount(transactionError.amount)}`,
                `${getErrorStatus(transactionError.status).name}`,
                `${transactionError.tries}/240`
            ]);
        },
        selectionToErrorServer(selection, getCompanyName, getErrorType) {
            return selection.map(transactionError => [
                transactionError.id.toString(),
                getCompanyName(transactionError.companyId),
                getErrorType(transactionError.errorType),
                transactionError.description,
                transactionError.httpStatus,
                transactionError.creationDate,
            ]);
        },
        selectionToGigs(selection) {
            return selection.map(gig => {
                const startTime = new Date(gig.paycInitiator.submittedStartTime);
                const endTime = new Date(gig.paycInitiator.submittedEndTime);
                const date = new Date(gig.updateDate).toLocaleDateString('nl-NL');
                return {
                    'id': gig.id,
                    'date': date,
                    'startTime': `${startTime.toLocaleDateString('nl-NL')} - ${startTime.getHours()}:${this.getMinute(startTime.getMinutes())}`,
                    'endTime': `${endTime.toLocaleDateString('nl-NL')} - ${endTime.getHours()}:${this.getMinute(endTime.getMinutes())}`,
                    'hourRate': this.getAmount(gig.paycInitiator.hourlyRate),
                    'provisionalGrossIncome': this.getAmount(gig.paycInitiator.provisionalGrossIncome),
                    'provisionalIncome': this.getAmount(gig.totalAmount || gig.amount),
                    'paymentStatus': gig.status
                };
            })
                .map(gig => [
                    gig.id, gig.date, gig.startTime, gig.endTime, gig.hourRate,
                    gig.provisionalGrossIncome, gig.provisionalIncome, gig.paymentStatus
                ]);
        },
        selectionToPayrollCsv(selection, getStatus, getBalance) {
            return selection.map(payroll => [
                payroll.id.toString(),
                payroll.uid.toString(),
                payroll.name,
                new Date(payroll.creationDate).toLocaleDateString('nl-NL'),
                payroll.groupId,
                this.getAmount(payroll.netIncome),
                this.getAmount(payroll.walIn),
                this.getAmount(payroll.walOut),
                getBalance(payroll),
                this.$t(getStatus(payroll.status).name)
            ]);
        },
        selectionToBalance(selection) {
            return selection.map(deposit => [
                deposit.depositId.toString(),
                new Date(deposit.creationDate).toLocaleDateString('nl-NL'),
                deposit.description,
                deposit.depositType,
                this.getAmount(deposit.amount),
                deposit.status]);
        },
        selectionToNotifications(selection) {
            return selection.map(notification => [
                notification.id,
                notification.mid,
                notification.platform,
                `${notification.type}/${notification.subType}`,
                notification.title,
            ]);
        },
        selectionToWallet(selection) {
            return selection.transactionsInfo.gigs.map(gig => ({
                'date': new Date(gig.updateDate).toLocaleDateString('nl-NL'),
                'description': gig.description,
                'amount': this.getAmount(gig.totalAmount || gig.amount)
            }))
                .map(gig => [gig.date, gig.description, gig.amount]);
        },
        selectionToInvoices(selection, getStatus, getCompany, getCorrectPaymentDate) {
            return selection.map(data =>
                [data.uid, `${data.firstname} ${data.lastname}`, data.refid,
                    `${new Date(data.invoiceDate).toDateString()}`,
                    `${new Date(data.payAssignment.payDate).toDateString()}`,
                    getCorrectPaymentDate(data),
                    this.getAmount(data.amount),
                    `${data.correctPercentageFee}`,
                    getStatus(data.isPaid, data.isEarlyPaid, data.isPayout).name,
                    getCompany(data.companyId),
                    data.payAssignment.employerName
                ]);
        },
        getCompanyInfoForCsv() {
            return this.companyInfoCsv;
        },
        createCSVForActiveWorkers(data, title) {
            const formattedData = this.selectionToActiveWorkers(data);
            const csvContent = this.createCSV(formattedData, this.activeWorkerHeader);
            this.downloadCsv(csvContent, title + this.formatCsv, this.csvMimeType);
        },
        createCSVForTransaction(data, title, getCompanyName, getStatus) {
            const formattedData = this.selectionToTransactions(data, getCompanyName, getStatus);
            const csvContent = this.createCSV(formattedData, this.transactionHeader);
            this.downloadCsv(csvContent, title + this.formatCsv, this.csvMimeType);
        },
        createCSVForServerError(data, title, getCompanyName, getErrorType) {
            const formattedData = this.selectionToErrorServer(data, getCompanyName, getErrorType);
            const csvContent = this.createCSV(formattedData, this.errorServerHeader);
            this.downloadCsv(csvContent, title + this.formatCsv, this.csvMimeType);
        },
        /* eslint-disable no-param-reassign */
        createCSV(formattedData, header) {
            let csvContent = header.reduce((accumulator, currentValue) => accumulator + ';' + currentValue) + '\n';
            formattedData.forEach((infoArray, index) => {
                // Error are in the index [3], but they could contain  the character ";",
                // this can lead the csv creation to have multiple columns.
                // In order to avoid that we change the char ";" to ","
                infoArray[3] = infoArray[3].replaceAll(';', ',');
                const dataString = infoArray.join(';');
                csvContent += index < formattedData.length ? dataString + '\n' : dataString;
            });
            return csvContent;
        },
        /* eslint-enable no-param-reassign */
        downloadCsv(content, fileName, mimeType) {
            const a = document.createElement('a');
            const realMimeType = mimeType || 'application/octet-stream';

            if (navigator.msSaveBlob) { // IE10
                navigator.msSaveBlob(new Blob([content], { type: realMimeType }), fileName);
            } else if (URL && 'download' in a) { //html5 A[download]
                a.href = URL.createObjectURL(new Blob([content], { type: realMimeType }));
                a.setAttribute('download', fileName);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
            }
        },
        getBlob(content, fileName, mimeType) {
            const a = document.createElement('a');
            const realMimeType = mimeType || 'application/octet-stream';
            if (navigator.msSaveBlob) { // IE10
                return new Blob([content], { type: realMimeType });
            } else if (URL && 'download' in a) { //html5 A[download]
                return new Blob([content], { type: realMimeType });
            } else {
                return 'data:application/octet-stream,' + encodeURIComponent(content);
            }
        },
        getMinute(minute) {
            return minute < 10 ? `0${minute}` : minute;
        },
        exportWorkerDetailCSV(selection, workerInfo, workerGigs, workerWallet) {
            const fullDate = this.getFormattedDate();
            const blobs = [];
            if (selection.includes('profile')) {
                const workerProfileContent = this.printWorkerProfileDetailCSV(workerInfo, this.workerProfileHeaderCsv);
                const blobProfile = this.getBlob(workerProfileContent, `worker_Profile_${workerInfo.lastname}_${fullDate}_` + this.formatCsv, this.csvMimeType);
                blobs.push({
                    name: 'profile',
                    file: blobProfile
                });
            }
            if (selection.includes('wallet')) {
                const workerGigsContent = this.printWorkerGigsCSV(workerGigs, this.workerGigHeaderCsv);
                const blobWallet = this.getBlob(workerGigsContent, `worker_Gigs_${workerInfo.lastname}_${fullDate}_` + this.formatCsv, this.csvMimeType);
                blobs.push({
                    name: 'wallet',
                    file: blobWallet
                });
            }
            if (selection.includes('gigs')) {
                const workerWalletContent = this.printWorkerWalletCSV(workerWallet, this.walletGigHeaderCsv);
                const blobGig = this.getBlob(workerWalletContent, `worker_Content_${workerInfo.lastname}_${fullDate}_` + this.formatCsv, this.csvMimeType);
                blobs.push({
                    name: 'gigs',
                    file: blobGig
                });
            }
            this.exportZipFile(blobs);
        },
        exportZipFile(blobs) {
            const zip = new JsZip();
            blobs.forEach(blob => {
                zip.file(`file-${blob.name}.csv`, blob.file);
            });
            zip.generateAsync({ type: 'blob' })
                .then(zipFile => {
                    const currentDate = new Date().getTime();
                    const fileName = `worker-profile-${currentDate}.zip`;
                    return FileSaver.saveAs(zipFile, fileName);
                });
        },
        printWorkerProfileDetailCSV(workerInfo, header) {
            let csvContent = header.reduce((accumulator, currentValue) => accumulator + ';' + currentValue) + '\n';
            return csvContent +
          `${workerInfo.id.toString()};${workerInfo.company};${workerInfo.firstname};${workerInfo.lastname};${workerInfo.email};${workerInfo.phoneNumber};${workerInfo.iban};\n`
          + '\n';
        },
        printWorkerGigsCSV(workerWallet, header) {
            let csvContent = header.reduce((accumulator, currentValue) => accumulator + ';' + currentValue) + '\n';
            const formattedData = this.selectionToGigs(workerWallet);
            formattedData.forEach((infoArray, index) => {
                const dataString = infoArray.join(';');
                csvContent += index < formattedData.length ? dataString + '\n' : dataString;
            });
            return csvContent + '\n';
        },
        printWorkerWalletCSV(workerGigs, header) {
            let csvContent = header.reduce((accumulator, currentValue) => accumulator + ';' + currentValue) + '\n';
            const formattedData = this.selectionToWallet(workerGigs);
            formattedData.forEach((infoArray, index) => {
                const dataString = infoArray.join(';');
                csvContent += index < formattedData.length ? dataString + '\n' : dataString;
            });
            return csvContent + '\n';
        },
        createCSVForPayrollCsv(data, title, getStatus, getBalance) {
            const formattedData = this.selectionToPayrollCsv(data, getStatus, getBalance);
            const csvContent = this.createCSV(formattedData, this.payrollHeaderCSV);
            this.downloadCsv(csvContent, title + this.formatCsv, this.csvMimeType);
        },
        createCSVForBalanceAndDeposit(data, title) {
            const formattedData = this.selectionToBalance(data);
            const csvContent = this.createCSV(formattedData, this.balanceAndDepositHeaderCSV);
            this.downloadCsv(csvContent, title + this.formatCsv, this.csvMimeType);
        },
        createCSVForNotification(data, title) {
            const formattedData = this.selectionToNotifications(data);
            const csvContent = this.createCSV(formattedData, this.notificationHeaderCSV);
            this.downloadCsv(csvContent, title + this.formatCsv, this.csvMimeType);
        },
        createCSVForInvoice(data, title, getStatus, getCompany,getCorrectPaymentDate) {
            const formattedData = this.selectionToInvoices(data, getStatus, getCompany,getCorrectPaymentDate);
            const csvContent = this.createCSV(formattedData, this.invoiceHeaderCSV);
            this.downloadCsv(csvContent, title + this.formatCsv, this.csvMimeType);
        }
    }
};
</script>
