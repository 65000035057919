Object.defineProperty(Object.prototype, "distinct", {
    value: function distinct() {
        const responseObj = [];
        for (let i in this) {
            const index = `_${this[i].refid}_${this[i].id}_${this[i].mid}_${this[i].companyName}_${this[i].paymentType}_${this[i].status}_${this[i].creationDate}_`;
            responseObj[index] = this[i];
        }
        return Object.values(responseObj).map(it => it);
    },
    writable: true,
    configurable: true,
});

Object.defineProperty(Object.prototype, "simpleDistinct", {
    value: function simpleDistinct() {
        const responseObj = [];
        for (let i in this) {
            const index = `_${this[i]}_`;
            responseObj[index] = this[i];
        }
        return Object.values(responseObj).map(it => it);
    },
    writable: true,
    configurable: true,
});