<template>
  <div></div>
</template>
<script>
import 'jspdf-autotable';
import {jsPDF} from 'jspdf';
import logo from '@/assets/images/logo_trasparent.png';
import logoAbn from '@/assets/images/logo-abn-amro.png';
import FormatUtils from '@/utils/FormatUtils';
import PaymentType from "@/utils/PaymentType";
import {getWorkerStatus} from "@/utils/WorkerStatus";
export default {
    name: 'PdfUtils',
    mixins: [FormatUtils],
    data() {
        return {
            companyInfoForPdf: {
                companyName: 'ABN AMRO Bank N.V.',
                contactName: 'Rienk Franken',
                homePhone: '06-1234567890',
                mobilePhone: '+31 612312312',
                email: 'payday.support@nl.abnamro.com',
                iban: 'NL68ABNA3675462129',
                countryCode: 'NLABNA123',
                otherInfo: 'Gustava Mahlerlaan 10,\n1082PP, Amsterdam',
            },
            activeWorkerHeader: ['id', 'creationDate', 'company', 'userType',
                'firstname', 'lastname', 'email', 'iban', 'balance', 'onboarded', 'status'],
            activeWorkerProperties: {
                title: 'PayDay - Active Users',
                subject: 'Exported active users',
                author: 'PayDay',
                keywords: 'payday, active, users',
                creator: 'PayDay'
            },
            inactiveWorkerHeader: ['id', 'creationDate', 'company', 'userType', 'firstname', 'lastname', 'email', 'iban', 'status'],
            inactiveWorkerProperties: {
                title: 'PayDay - Inactive Users',
                subject: 'Exported Inactive users',
                author: 'PayDay',
                keywords: 'payday, Inactive, users',
                creator: 'PayDay'
            },
            transactionHeader: ['User ID', 'Ref ID', 'Company', 'Name', 'Description', 'Iban', 'Creation Date', 'Update Date', 'Exec Date', 'Type', 'Amount', 'Status', 'Status reason'],
            transactionProperties: {
                title: 'PayDay - Transactions',
                subject: 'Exported Transactions',
                author: 'PayDay',
                keywords: 'payday, transactions',
                creator: 'PayDay'
            },
            errorPisHeader: ['User ID', 'company', 'name', 'Creation Date', 'Update Date', 'Type', 'Amount', 'Status', 'Tries',],
            errorPisProperties: {
                title: 'PayDay - PIS Errors',
                subject: 'Exported PIS Errors',
                author: 'PayDay',
                keywords: 'payday, error, pis',
                creator: 'PayDay'
            },
            errorServerHeader: ['ErrorID', 'company', 'type', 'Description', 'HTTP Status', 'Date'],
            errorServerProperties: {
                title: 'PayDay - Server Errors',
                subject: 'Exported Server Errors',
                author: 'PayDay',
                keywords: 'payday, error, server',
                creator: 'PayDay'
            },
            workerProfileProperties: {
                title: 'PayDay - Worker Details',
                subject: 'Exported worker details',
                author: 'PayDay',
                keywords: 'payday, worker, details',
                creator: 'PayDay'
            },
            workerGigHeader: ['Id', 'Update Time', 'Start Time', 'End Time', 'Hour Rate', 'Gross Income', 'Provisional Income', 'Status',],
            walletGigHeader: ['Date', 'Description', 'Amount'],
            basicPdfProperties: {
                putOnlyUsedFonts: true,
                orientation: 'l',
                unit: 'mm',
                format: 'a4',
            },
            payrollHeader: ['Payroll Id', 'User Id',
                'Name', 'Date',
                'Period', 'Net Income',
                'Incoming', 'Outgoing',
                'Balance', 'Status'],
            payrollProperties: {
                title: 'PayDay - Payrolls',
                subject: 'Exported Payrolls',
                author: 'PayDay',
                keywords: 'payday, worker, Payroll',
                creator: 'PayDay'
            },
            payrollDetailHeader: ['User Id',
                'Creation Date', 'Update Date', 'Period',
                'Employer Name', 'Description', 'Is Settlement',
                'Is Hour Correction', 'Amount', 'Correction'],
            payrollDetailHeaderForIncoming: [
                'Id', 'Name', 'Iban', 'Employee', 'Employer',
                'Pay Period', 'Payment Date', 'Payment Type',
                'Amount', 'Status',
            ],
            payrollDetailHeaderForPayout: [
                'Transaction Id', 'Name',
                'Iban', 'Employer',
                'Pay Period',
                'Payment Date', 'Payment Type',
                'Amount', 'Status',
            ],
            payrollDetailHeaderForNets: [
                'Id', 'Creation Date', 'Amount'
            ],
            payrollDetailProperties: {
                title: 'PayDay - Payroll Details',
                subject: 'Exported Payroll details',
                author: 'PayDay',
                keywords: 'payday, worker,Payroll, details',
                creator: 'PayDay'
            },
            depositAndBalanceProperties: {
                title: 'PayDay - Deposit & Balance Details',
                subject: 'Exported Deposit & Balance details',
                author: 'PayDay',
                keywords: 'payday, deposit, balance, details',
                creator: 'PayDay'
            },
            depositAndBalanceHeader: ['Deposit Id', 'Date', 'Description', 'Type', 'Amount', 'Status'],
            notificationHeader: ['Notification Id', 'User Id', 'Platform', 'Type', 'Title'],
            notificationsProperties: {
                title: 'PayDay - Notifications Details',
                subject: 'Exported Notifications details',
                author: 'PayDay',
                keywords: 'payday, notifications, details',
                creator: 'PayDay',
            },
            invoiceProperties: {
                title: 'PayDay - Invoice Details',
                subject: 'Exported Invoice details',
                author: 'PayDay',
                keywords: 'payday, Invoice, details, unique',
                creator: 'PayDay'
            },
            invoiceHeader: [
                'P. user Id', 'Name', 'Invoice Number',
                'Invoice Date', 'Due Date', 'Pay Date', 'Amount', 'Fee', 'Status',
                'Company', 'Employer'
            ],
            defaultMiniMargin: 5,
            defaultMarginSizeX: 10,
            defaultMarginSizeY: 10,
            defaultMarginSizeZ: 15,
            defaultLogoW: 45,
            defaultLogoABNW: 30,
            defaultLogoH: 10,
            defaultTableStartY: 70,
        };
    },
    methods: {
        selectionToActiveWorkersPdf(selection) {
            return selection.map(worker => ({
                'id': worker.id.toString(),
                'creationDate': new Date(worker.creationDate).toLocaleString('nl-NL'),
                'company': worker.company,
                'userType': worker.userType,
                'firstname': worker.firstname,
                'lastname': worker.lastname,
                'email': worker.email,
                'iban': worker.iban,
                'balance': `€ ${worker.balance}`,
                'onboarded': worker.onBoarded ? 'Yes' : 'No',
                'status': getWorkerStatus(worker.status)
            }));
        },
        selectionToTransactionsPdf(selection, getCompanyName) {
            return selection.map(transaction => ({
                'uid': transaction.uid.toString(),
                'refid': transaction.refid ? `${transaction.refid}` : `-`,
                'companyId': getCompanyName(transaction.companyId),
                'counterpartyName': transaction.counterpartyName,
                'description': transaction.description ? transaction.description.toString() : 'No Description',
                'counterpartyIban': transaction.counterpartyIban,
                'creationDate': this.getDateByTransaction(transaction.creationDate),
                'updateDate': this.getDateByTransaction(transaction.updateDate),
                'executionDate': this.getDateByTransaction(transaction.executionDate),
                'paymentType': this.$t(PaymentType[transaction.paymentType].name),
                'amount': `${this.getAmount(transaction.amount || transaction.totalAmount)}`,
                'status': `${transaction.status}`,
                'reasonNote': `${transaction.reasonNote}`,
            }));
        },
        selectionToErrorServerPdf(selection, getCompanyName, getErrorType) {
            return selection.map(transactionError => ({
                'id': transactionError.id.toString(),
                'company': getCompanyName(transactionError.companyId),
                'type': getErrorType(transactionError.errorType),
                'description': transactionError.description,
                'httpStatus': transactionError.httpStatus,
                'creationDate': transactionError.creationDate,
            }));
        },
        selectionToWorkerGigsPdf(selection) {
            return selection.map(gig => {
                const startTime = new Date(gig.paycInitiator.submittedStartTime);
                const endTime = new Date(gig.paycInitiator.submittedEndTime);
                const date = new Date(gig.updateDate).toLocaleDateString('nl-NL');
                return {
                    'id': gig.id,
                    'date': date,
                    'startTime': `${startTime.toLocaleDateString('nl-NL')} - ${startTime.getHours()}:${this.getMinute(startTime.getMinutes())}`,
                    'endTime': `${endTime.toLocaleDateString('nl-NL')} - ${endTime.getHours()}:${this.getMinute(endTime.getMinutes())}`,
                    'hourRate': this.getAmount(gig.paycInitiator.hourlyRate),
                    'provisionalGrossIncome': this.getAmount(gig.paycInitiator.provisionalGrossIncome),
                    'provisionalIncome': this.getAmount(gig.totalAmount || gig.amount),
                    'paymentStatus': gig.status
                };
            })
                .map(gig => [
                    gig.id, gig.date, gig.startTime, gig.endTime, gig.hourRate,
                    gig.provisionalGrossIncome, gig.provisionalIncome, gig.paymentStatus
                ]);
        },
        selectionToPayrollDetailPdf(selection) {
            /* eslint-disable no-param-reassign */
            return selection.walInPayments.map(transaction => {
                return {
                    'UserId': transaction.uid.toString(),
                    'CreationDate': new Date(transaction.creationDate).toLocaleDateString(),
                    'UpdateDate': new Date(transaction.updateDate).toLocaleDateString(),
                    'Period': transaction.groupId.toString(),
                    'EmployerName': !this.isSettlement(transaction) ? transaction.paycInitiator.employerName : 'Settlement payment period',
                    'Description': transaction.description,
                    'isSettlement': this.isSettlement(transaction) ? 'Yes' : 'No',
                    'isHourAdj': this.isHourAdjustment(transaction) ? 'Yes' : 'No',
                    'Amount': this.getAmount(transaction.amount || transaction.totalAmount),
                    'Correction': this.isHourAdjustment(transaction) ? this.getAmount(transaction.paycAdjustments.map(t => t.totalHourAdjustment)
                        .reduce((acc, val) => parseFloat(acc) + parseFloat(val))) : 'N/A',
                };
            })
                .map(t => [t.UserId, t.CreationDate, t.UpdateDate, t.Period, t.EmployerName, t.Description, t.isSettlement, t.isHourAdj, t.Amount, t.Correction]);
            /* eslint-enable no-param-reassign */
        },
        selectionToPayrollPdf(selection, getStatus, getBalance) {
            return selection
                .map(payroll => {
                    return [
                        payroll.id.toString(),
                        payroll.uid.toString(),
                        payroll.name,
                        new Date(payroll.creationDate).toLocaleDateString('nl-NL'),
                        payroll.groupId,
                        this.getAmount(payroll.netIncome),
                        this.getAmount(payroll.walIn),
                        this.getAmount(payroll.walOut),
                        getBalance(payroll),
                        getStatus(payroll.status)
                    ];
                });
        },
        selectionToNotificationPdf(selection) {
            return selection
                .map(notification => [
                    notification.id,
                    notification.mid,
                    notification.platform,
                    `${notification.type}/${notification.subType}`,
                    notification.title,
                ]);
        },
        getCompanyInfoForPdf() {
            return this.companyInfoForPdf;
        },
        addExportInfoOnTopLeft(doc, title, exportId, date) {
            // Set Info Title
            doc.setFontSize(16);
            doc.text(title, this.defaultMarginSizeX, 55);
            doc.text('Date of issue', 65, 55);
            // Set info content
            doc.setFontSize(12);
            doc.text(`Export ID: ${exportId}`, this.defaultMarginSizeX, 60);
            doc.text(date, 65, 60);
        },
        addCompanyInfoOnTopRight(doc) {
            const leftStartingPoint = 280;
            const alignment = 'right';
            const companyInfo = this.getCompanyInfoForPdf();
            doc.text(companyInfo.companyName, leftStartingPoint, 18, null, null, alignment);
            doc.text(companyInfo.otherInfo, leftStartingPoint, 23, null, null, alignment);
            doc.text(companyInfo.email, leftStartingPoint, 33, null, null, alignment);
        },
        addFooters(doc) {
            const pageCount = doc.internal.getNumberOfPages();
            const heightForPageCounter = doc.internal.pageSize.height - 5;
            const heightForLogo = doc.internal.pageSize.height - 10;
            doc.setFontSize(8);
            for (let i = 1; i <= pageCount; i++) {
                doc.setPage(i);
                doc.text(`Page ${String(i)} of ${String(pageCount)}`, this.defaultMarginSizeX, heightForPageCounter, {align: 'left'});
                doc.addImage(logoAbn, 'PNG', 250, heightForLogo, this.defaultLogoABNW, this.defaultLogoH);
            }
        },
        addTableContentForActiveWorkers(myData, fullDate) {
            const doc = new jsPDF(this.basicPdfProperties);
            const pageHeight = doc.internal.pageSize.getHeight();
            const imgHeight = this.$refs.table.clientHeight * 25.4 / 96; //px to mm
            const pageCount = Math.ceil(imgHeight / pageHeight);
            const dateInString = fullDate.replaceAll(' ', '-')
                .replaceAll(':', '-');
            doc.setProperties(this.activeWorkerProperties);
            const header = this.activeWorkerHeader;
            const formattedData = this.selectionToActiveWorkersPdf(myData);
            doc.addImage(logo, 'PNG', this.defaultMarginSizeX, this.defaultMarginSizeY, this.defaultLogoW, this.defaultLogoH);
            this.addExportInfoOnTopLeft(doc, 'Active Users', Date.now().toString(), fullDate);
            this.addCompanyInfoOnTopRight(doc, this.companyInfoForPdf);

            const formattedBody = formattedData.map(data =>
                [data.id, data.creationDate, data.company, data.userType,
                    data.firstname, data.lastname, data.email, data.iban,
                    data.balance, data.onboarded, data.status]
            );
            doc.autoTable({
                head: [header],
                body: formattedBody,
                startY: this.defaultTableStartY,
                theme: 'striped',
                margin: {
                    top: this.defaultMarginSizeY,
                    left: this.defaultMiniMargin,
                    right: this.defaultMiniMargin,
                    bottom: this.defaultMarginSizeZ,
                },
                /* eslint-disable no-param-reassign */
                willDrawCell: (data) => {
                    if (data.section === 'body' && data.column.index === 10) {
                        const status = data.cell.raw;
                        doc.setTextColor(status.color[0], status.color[1], status.color[2]);
                        data.cell.styles.halign = 'center';
                        data.cell.text = this.$t(status.name);
                    }
                },
                didDrawCell: (data) => {
                    if (data.section === 'body' && data.column.index === 10) {
                        const status = data.cell.raw;
                        doc.addImage(status.img, 'PNG', data.cell.x, data.cell.y + 1, 5, 5);
                        data.cell.styles.fillColor = [status.color[0], status.color[1], status.color[2]];
                        data.cell.styles.halign = 'left';
                    }
                }
                /* eslint-enable no-param-reassign */
            });
            this.addPagesToPdfExport(doc, pageCount);
            this.addFooters(doc);
            doc.save(`export-active-users_${dateInString}.pdf`);
        },
        addPagesToPdfExport(doc, pageCount) {
            if (pageCount > 0) {
                let j = 1;
                while (j !== pageCount) {
                    doc.addPage('l', 'mm', 'a4');
                    j++;
                }
            }
        },
        addTableContentForTransaction(myData, fullDate, getStatus, getCompanyName) {
            const doc = new jsPDF(this.basicPdfProperties);
            const pageHeight = doc.internal.pageSize.getHeight();
            const imgHeight = this.$refs.table.clientHeight * 25.4 / 96; //px to mm
            const pageCount = Math.ceil(imgHeight / pageHeight);
            const dateInString = fullDate.replaceAll(' ', '-')
                .replaceAll(':', '-');
            doc.setProperties(this.transactionProperties);
            const header = this.transactionHeader;
            const formattedData = this.selectionToTransactionsPdf(myData, getCompanyName);
            doc.addImage(logo, 'PNG', this.defaultMarginSizeX, this.defaultMarginSizeY, this.defaultLogoW, this.defaultLogoH);
            this.addExportInfoOnTopLeft(doc, 'Transactions', Date.now()
                .toString(), fullDate);
            this.addCompanyInfoOnTopRight(doc, this.companyInfoForPdf);
            const formattedBody = formattedData.map(data => [data.uid, data.refid, data.companyId, data.counterpartyName, data.description, data.counterpartyIban, data.creationDate,
                data.updateDate, data.executionDate, data.paymentType, data.amount, data.status, data.reasonNote]);
            doc.autoTable({
                head: [header],
                body: formattedBody,
                startY: this.defaultTableStartY,
                theme: 'striped',
                margin: {
                    top: this.defaultMarginSizeY,
                    left: this.defaultMarginSizeX,
                    right: this.defaultMarginSizeX,
                    bottom: this.defaultMarginSizeZ,
                },
                /* eslint-disable no-param-reassign */
                willDrawCell: (data) => {
                    if (data.section === 'body' && data.column.index === 11) {
                        const status = getStatus(data.cell.raw);
                        doc.setTextColor(status.color[0], status.color[1], status.color[2]);
                        data.cell.styles.halign = 'center';
                        data.cell.text = this.$t(status.name);
                    }
                },
                didDrawCell: (data) => {
                    if (data.section === 'body' && data.column.index === 11) {
                        const status = getStatus(data.cell.raw);
                        doc.addImage(status.img, 'PNG', data.cell.x, data.cell.y + 1, 5, 5);
                        data.cell.styles.fillColor = [status.color[0], status.color[1], status.color[2]];
                        data.cell.styles.halign = 'center';
                    }
                }
                /* eslint-enable no-param-reassign */
            });
            this.addPagesToPdfExport(doc, pageCount);
            this.addFooters(doc);
            doc.save(`export-transactions-${dateInString}.pdf`);
        },
        addTableForServerContent(myData, fullDate, getErrorStatus, getCompanyName, getErrorType) {
            const doc = new jsPDF(this.basicPdfProperties);
            const pageHeight = doc.internal.pageSize.getHeight();
            const imgHeight = this.$refs.table.clientHeight * 25.4 / 96; //px to mm
            const pageCount = Math.ceil(imgHeight / pageHeight);
            const dateInString = fullDate.replaceAll(' ', '-')
                .replaceAll(':', '-');
            doc.setProperties(this.errorServerProperties);
            const header = this.errorServerHeader;
            const formattedData = this.selectionToErrorServerPdf(myData, getCompanyName, getErrorType);
            const companyInfo = this.getCompanyInfoForPdf();
            doc.addImage(logo, 'PNG', this.defaultMarginSizeX, this.defaultMarginSizeY, this.defaultLogoW, this.defaultLogoH);
            this.addExportInfoOnTopLeft(doc, 'Server Errors', Date.now()
                .toString(), fullDate);
            this.addCompanyInfoOnTopRight(doc, companyInfo);
            const formattedBody = formattedData.map(data => [data.id, data.company, data.type, data.description, data.httpStatus, data.creationDate]);
            doc.autoTable({
                head: [header],
                body: formattedBody,
                startY: this.defaultTableStartY,
                theme: 'striped',
                margin: {
                    top: this.defaultMarginSizeY,
                    left: this.defaultMarginSizeX,
                    right: this.defaultMarginSizeZ,
                    bottom: this.defaultMarginSizeZ,
                },
            });
            this.addPagesToPdfExport(doc, pageCount);
            this.addFooters(doc);
            doc.save(`export-server-errors_${dateInString}.pdf`);
        },
        exportWorkerDetailPdf(selection, workerInfo, workerGigs, workerWallet) {
            const doc = new jsPDF(this.basicPdfProperties);
            doc.setProperties(this.workerProfileProperties);
            doc.addImage(logo, 'PNG', this.defaultMarginSizeX, this.defaultMarginSizeX, 35, this.defaultMarginSizeX);
            const date = Date.now()
                .toString();
            const fullDate = this.getFormattedDate();
            this.addExportInfoOnTopLeft(doc, 'Worker Details', date, fullDate);
            this.addCompanyInfoOnTopRight(doc);
            if (selection.includes('profile')) {
                this.exportWorkerProfile(doc, workerInfo);
            }
            if (selection.includes('wallet')) {
                this.exportWorkerWallet(doc, workerWallet);
            }
            if (selection.includes('gigs')) {
                this.exportWorkerGigs(doc, workerGigs);
            }
            this.addFooters(doc);
            doc.save(`worker-detail-${fullDate}.pdf`);
        },
        addTableContentForInvoice(selection, fullDate, getStatus, getCompany, getCorrectPaymentDate) {
            const doc = new jsPDF(this.basicPdfProperties);
            const pageHeight = doc.internal.pageSize.getHeight();
            const imgHeight = this.$refs.table.clientHeight * 25.4 / 96; //px to mm
            const pageCount = Math.ceil(imgHeight / pageHeight);
            const dateInString = fullDate
                .replaceAll(' ', '-')
                .replaceAll(':', '-');
            doc.setProperties(this.invoiceProperties);
            const header = this.invoiceHeader;
            const formattedData = selection;
            doc.addImage(logo, 'PNG', this.defaultMarginSizeX, this.defaultMarginSizeY, this.defaultLogoW, this.defaultLogoH);
            this.addExportInfoOnTopLeft(doc, 'Invoices', Date.now()
                .toString(), fullDate);
            this.addCompanyInfoOnTopRight(doc, this.companyInfoForPdf);
            const formattedBody = formattedData.map(data =>
                [data.uid, `${data.firstname} ${data.lastname}`, data.refid,
                    `${new Date(data.invoiceDate).toDateString()}`,
                    `${new Date(data.payAssignment.payDate).toDateString()}`,
                    getCorrectPaymentDate(data),
                    this.getAmount(data.amount),
                    `${data.correctPercentageFee}`,
                    getStatus(data.isPaid, data.isEarlyPaid, data.isPayout),
                    getCompany(data.companyId),
                    data.payAssignment.employerName
                ]
            );
            doc.autoTable({
                head: [header],
                body: formattedBody,
                startY: this.defaultTableStartY,
                theme: 'striped',
                margin: {
                    top: this.defaultMarginSizeY,
                    left: this.defaultMarginSizeX,
                    right: this.defaultMarginSizeZ,
                    bottom: this.defaultMarginSizeZ,
                },
                /* eslint-disable no-param-reassign */
                willDrawCell: (data) => {
                    if (data.section === 'body' && data.column.index === 8) {
                        const status = data.cell.raw;
                        doc.setTextColor(status.color[0], status.color[1], status.color[2]);
                        data.cell.styles.halign = 'center';
                        data.cell.text = status.name;
                    }
                },
                didDrawCell: (data) => {
                    if (data.section === 'body' && data.column.index === 8) {
                        const status = data.cell.raw;
                        doc.addImage(status.img, 'PNG', data.cell.x, data.cell.y + 1, 5, 5);
                        data.cell.styles.fillColor = [status.color[0], status.color[1], status.color[2]];
                        data.cell.styles.halign = 'center';
                    }
                }
                /* eslint-enable no-param-reassign */
            });
            this.addPagesToPdfExport(doc, pageCount);
            this.addFooters(doc);
            doc.save(`export-invoice-${dateInString}.pdf`);
        },
        addTableContentForBalanceAndDeposit(selection, fullDate, getStatus) {
            const doc = new jsPDF(this.basicPdfProperties);
            const pageHeight = doc.internal.pageSize.getHeight();
            const imgHeight = this.$refs.table.clientHeight * 25.4 / 96; //px to mm
            const pageCount = Math.ceil(imgHeight / pageHeight);
            const dateInString = fullDate.replaceAll(' ', '-')
                .replaceAll(':', '-');
            doc.setProperties(this.depositAndBalanceProperties);
            const header = this.depositAndBalanceHeader;
            const formattedData = selection;
            doc.addImage(logo, 'PNG', this.defaultMarginSizeX, this.defaultMarginSizeY, this.defaultLogoW, this.defaultLogoH);
            this.addExportInfoOnTopLeft(doc, 'Deposits', Date.now()
                .toString(), fullDate);
            this.addCompanyInfoOnTopRight(doc, this.companyInfoForPdf);
            const formattedBody = formattedData.map(data => [data.depositId, data.creationDate, data.description,
                data.depositType, this.getAmount(data.amount), data.status]);
            doc.autoTable({
                head: [header],
                body: formattedBody,
                startY: this.defaultTableStartY,
                theme: 'striped',
                margin: {
                    top: this.defaultMarginSizeY,
                    left: this.defaultMarginSizeX,
                    right: this.defaultMarginSizeZ,
                    bottom: this.defaultMarginSizeZ,
                },
                /* eslint-disable no-param-reassign */
                willDrawCell: (data) => {
                    if (data.section === 'body' && data.column.index === 5) {
                        const status = getStatus(data.cell.raw);
                        doc.setTextColor(status.color[0], status.color[1], status.color[2]);
                        data.cell.styles.halign = 'center';
                        data.cell.text = status.name;
                    }
                },
                didDrawCell: (data) => {
                    if (data.section === 'body' && data.column.index === 5) {
                        const status = getStatus(data.cell.raw);
                        doc.addImage(status.img, 'PNG', data.cell.x, data.cell.y + 1, 5, 5);
                        data.cell.styles.fillColor = [status.color[0], status.color[1], status.color[2]];
                        data.cell.styles.halign = 'center';
                    }
                }
                /* eslint-enable no-param-reassign */
            });
            this.addPagesToPdfExport(doc, pageCount);
            this.addFooters(doc);
            doc.save(`export-deposit-and-balance-${dateInString}.pdf`);
        },
        exportWorkerProfile(doc, workerInfo) {
            doc.setFontSize(23);
            doc.text(`Personal Information: `, this.defaultMarginSizeX, this.defaultTableStartY);
            doc.setFontSize(this.defaultMarginSizeZ,);
            doc.text(`User ID: ${workerInfo.id.toString()}`, this.defaultMarginSizeX, 77);
            doc.text(`Company: ${workerInfo.company}`, this.defaultMarginSizeX, 84);
            doc.text(`Firstname: ${workerInfo.firstname}`, this.defaultMarginSizeX, 91);
            doc.text(`Lastname: ${workerInfo.lastname}`, this.defaultMarginSizeX, 98);
            doc.text(`Email: ${workerInfo.email}`, this.defaultMarginSizeX, this.defaultMarginSizeX5);
            doc.text(`Phone Number: ${workerInfo.phoneNumber}`, this.defaultMarginSizeX, 111);
            doc.text(`Iban: ${workerInfo.iban}`, this.defaultMarginSizeX, 118);
        },
        exportWorkerGigs(doc, workerGigs) {
            doc.addPage('l', 'mm', 'a4');
            const workersGigMap = this.selectionToWorkerGigsPdf(workerGigs);
            doc.setFontSize(23);
            doc.text(`Worker gigs detail`, this.defaultMarginSizeX, this.defaultMarginSizeX);
            doc.autoTable({
                head: [this.workerGigHeader],
                body: workersGigMap,
                startY: 20,
                theme: 'striped',
                margin: {
                    top: this.defaultMarginSizeY,
                    left: this.defaultMarginSizeX,
                    right: this.defaultMarginSizeZ,
                    bottom: this.defaultMarginSizeZ,
                }
            });
        },
        exportWorkerWallet(doc, workerWallet) {
            doc.setFontSize(23);
            doc.text(`Wallet: `, 200, this.defaultTableStartY);
            doc.setFontSize(this.defaultMarginSizeZ,);
            doc.text(`Total Incoming: ${this.getAmount(workerWallet.totalIncoming)}`, 200, 77);
            doc.text(`Total Outgoing : ${this.getAmount(workerWallet.totalOutgoing)}`, 200, 84);
            doc.text(`Wallet Balance : ${this.getAmount(workerWallet.walletBalance)}`, 200, 91);
            doc.addPage('l', 'mm', 'a4');
            const transaction = workerWallet.transactionsInfo.gigs.map(gig => ({
                'date': new Date(gig.updateDate).toLocaleDateString('nl-NL'),
                'description': gig.description,
                'amount': this.getAmount(gig.totalAmount || gig.amount)
            }))
                .map(gig => [gig.date, gig.description, gig.amount]);

            doc.setFontSize(23);
            doc.text(`Wallet Transaction: `, this.defaultMarginSizeX, this.defaultMarginSizeX);
            doc.autoTable({
                head: [this.walletGigHeader],
                body: transaction,
                startY: 20,
                theme: 'striped',
                margin: {
                    top: this.defaultMarginSizeY,
                    left: this.defaultMarginSizeX,
                    right: this.defaultMarginSizeZ,
                    bottom: this.defaultMarginSizeZ,
                }
            });
        },
        mapPayrollDetailToWalInPay(selection, getStatus, getCompany, getAmountFormat) {
            const companyName = getCompany(selection.companyId);
            return selection.walInPayments.map(payroll => [
                payroll.refid, payroll.counterpartyName, payroll.counterpartyIban,
                payroll.paycInitiator.employerName ? payroll.paycInitiator.employerName : "N/A", companyName,
                selection.groupId, new Date(payroll.creationDate).toLocaleDateString('nl-NL'), this.$t(PaymentType[payroll.paymentType].name),
                getAmountFormat(payroll.totalAmount), this.$t(getStatus(payroll.status).name)
            ]);
        },
        mapPayrollDetailToWalOutPay(selection, getStatus, getCompany, getAmountFormat) {
            const companyName = getCompany(selection.companyId);
            return selection.walOutTransactions.map(payroll => [
                payroll.transactionId, payroll.counterpartyName,
                payroll.counterpartyIban, companyName,
                selection.groupId,
                new Date(payroll.creationDate).toLocaleDateString('nl-NL'),
                this.$t(PaymentType[payroll.paymentType].name),
                getAmountFormat(payroll.amount), this.$t(getStatus(payroll.status).name)
            ]);
        },
        mapPayrollDetailToNetPay(selection, getAmountFormat) {
            return selection.map(net => [net.id, new Date(net.creationDate).toLocaleDateString('nl-NL'), getAmountFormat(net.amount)]);
        },
        exportCompletePayrollPdf(fileName, payrollDetail, getStatus, getCompany, nets, getAmountFormat) {
            const doc = new jsPDF(this.basicPdfProperties);
            doc.setProperties(this.workerProfileProperties);
            doc.addImage(logo, 'PNG', this.defaultMarginSizeX, this.defaultMarginSizeY, this.defaultLogoW, this.defaultLogoH);
            const date = Date.now()
                .toString();
            const fullDate = this.getFormattedDate();
            this.addExportInfoOnTopLeft(doc, 'Payroll Details', date, fullDate);
            this.addCompanyInfoOnTopRight(doc);
            const walletPayIn = this.mapPayrollDetailToWalInPay(payrollDetail, getStatus, getCompany, getAmountFormat);
            const payouts = this.mapPayrollDetailToWalOutPay(payrollDetail, getStatus, getCompany, getAmountFormat);
            const net = this.mapPayrollDetailToNetPay(nets, getAmountFormat);
            this.exportPayrollInfo(doc, payrollDetail);
            doc.addPage('l', 'mm', 'a4');
            doc.setFontSize(23);
            doc.text(`Incoming`, this.defaultMarginSizeX, 20);
            const walletPayInTable = this.createAutoTable(doc, this.payrollDetailHeaderForIncoming, walletPayIn, 30);
            doc.text(`Outgoing`, this.defaultMarginSizeX, walletPayInTable.lastAutoTable.finalY + this.defaultMarginSizeX);
            const walletPayoutTable = this.createAutoTable(doc, this.payrollDetailHeaderForPayout, payouts, walletPayInTable.lastAutoTable.finalY + 20);
            doc.text(`Net Pay`, this.defaultMarginSizeX, walletPayoutTable.lastAutoTable.finalY + this.defaultMarginSizeX);
            this.createAutoTable(doc, this.payrollDetailHeaderForNets, net, walletPayoutTable.lastAutoTable.finalY + 20);
            this.addFooters(doc);
            doc.save(`${fileName}.pdf`);
        },
        createAutoTable(doc, header, body, startY = this.defaultMarginSizeY) {
            return doc.autoTable({
                head: [header],
                body: body,
                startY: startY,
                theme: 'striped',
                margin: {
                    top: this.defaultMarginSizeY,
                    left: this.defaultMarginSizeX,
                    right: this.defaultMarginSizeZ,
                    bottom: this.defaultMarginSizeZ,
                }
            });
        },
        exportPayrollPdf(payrollDetail) {
            const doc = new jsPDF(this.basicPdfProperties);
            doc.setProperties(this.workerProfileProperties);
            doc.addImage(logo, 'PNG', this.defaultMarginSizeX, this.defaultMarginSizeY, this.defaultLogoW, this.defaultLogoH);
            const date = Date.now()
                .toString();
            const fullDate = this.getFormattedDate();
            this.addExportInfoOnTopLeft(doc, 'Payroll Details', date, fullDate);
            this.addCompanyInfoOnTopRight(doc);
            const payrollInfo = {
                id: payrollDetail.id,
                firstname: payrollDetail.firstname,
                lastname: payrollDetail.lastname,
                creationDate: payrollDetail.creationDate,
                estimatedIncome: payrollDetail.estimatedIncome,
                totalNetAdjustment: payrollDetail.totalNetAdjustment,
            };
            this.exportPayrollInfo(doc, payrollInfo);
            doc.addPage('l', 'mm', 'a4');
            const listOfTransactions = this.selectionToPayrollDetailPdf(payrollDetail);
            doc.autoTable({
                head: [this.payrollDetailHeader],
                body: listOfTransactions,
                startY: 20,
                theme: 'striped',
                margin: {
                    top: this.defaultMarginSizeY,
                    left: this.defaultMarginSizeX,
                    right: this.defaultMarginSizeZ,
                    bottom: this.defaultMarginSizeZ,
                }
            });
            this.addFooters(doc);
            doc.save(`payroll-detail-${fullDate}.pdf`);
        },
        exportPayrollInfo(doc, payrollInfo) {
            doc.setFontSize(23);
            doc.text(`Report: `, this.defaultMarginSizeX, 77);
            doc.setFontSize(this.defaultMarginSizeZ,);
            doc.text(`User ID: ${payrollInfo.uid.toString()}`, this.defaultMarginSizeX, 84);
            doc.text(`Name: ${payrollInfo.name}`, this.defaultMarginSizeX, 91);
            doc.text(`Creation Date: ${new Date(payrollInfo.creationDate).toLocaleDateString('nl-NL')}`, this.defaultMarginSizeX, 98);
            doc.text(`Incoming: ${this.getAmount(payrollInfo.walIn)}`, this.defaultMarginSizeX, 105);
            doc.text(`Outgoing: ${this.getAmount(payrollInfo.walOut)}`, this.defaultMarginSizeX, 112);
            doc.text(`Net Pay: ${this.getAmount(payrollInfo.netIncome)}`, this.defaultMarginSizeX, 120);
        },
        addTableForNotificationContent(myData, fullDate) {
            const doc = new jsPDF(this.basicPdfProperties);
            const pageHeight = doc.internal.pageSize.getHeight();
            const imgHeight = this.$refs.table.clientHeight * 25.4 / 96; //px to mm
            const pageCount = Math.ceil(imgHeight / pageHeight);
            const dateInString = fullDate.replaceAll(' ', '-')
                .replaceAll(':', '-');
            doc.setProperties(this.notificationsProperties);
            const header = this.notificationHeader;
            const formattedData = this.selectionToNotificationPdf(myData);
            doc.addImage(logo, 'PNG', this.defaultMarginSizeX, this.defaultMarginSizeY, this.defaultLogoW, this.defaultLogoH);
            this.addExportInfoOnTopLeft(doc, 'User\'s Payrolls', Date.now()
                .toString(), fullDate);
            this.addCompanyInfoOnTopRight(doc, this.companyInfoForPdf);
            doc.autoTable({
                head: [header],
                body: formattedData,
                startY: this.defaultTableStartY,
                theme: 'striped',
                margin: {
                    top: this.defaultMarginSizeY,
                    left: this.defaultMarginSizeX,
                    right: this.defaultMarginSizeZ,
                    bottom: this.defaultMarginSizeZ,
                }
            });
            this.addPagesToPdfExport(doc, pageCount);
            this.addFooters(doc);
            doc.save(`export-notifications_${dateInString}.pdf`);
        },
        addTableContentForPayrollPdf(myData, fullDate, getStatus, getBalance) {
            const doc = new jsPDF(this.basicPdfProperties);
            const pageHeight = doc.internal.pageSize.getHeight();
            const imgHeight = this.$refs.table.clientHeight * 25.4 / 96; //px to mm
            const pageCount = Math.ceil(imgHeight / pageHeight);
            const dateInString = fullDate.replaceAll(' ', '-')
                .replaceAll(':', '-');
            doc.setProperties(this.payrollProperties);
            const header = this.payrollHeader;
            const formattedData = this.selectionToPayrollPdf(myData, getStatus, getBalance);
            doc.addImage(logo, 'PNG', this.defaultMarginSizeX, this.defaultMarginSizeY, this.defaultLogoW, this.defaultLogoH);
            this.addExportInfoOnTopLeft(doc, 'User\'s Payrolls', Date.now()
                .toString(), fullDate);
            this.addCompanyInfoOnTopRight(doc, this.companyInfoForPdf);
            doc.autoTable({
                head: [header],
                body: formattedData,
                startY: this.defaultTableStartY,
                theme: 'striped',
                margin: {
                    top: this.defaultMarginSizeY,
                    left: this.defaultMarginSizeX,
                    right: this.defaultMarginSizeZ,
                    bottom: this.defaultMarginSizeZ,
                },
                /* eslint-disable no-param-reassign */
                willDrawCell: (data) => {
                    if (data.section === 'body' && data.column.index === 9) {
                        const status = data.cell.raw;
                        doc.setTextColor(status.color[0], status.color[1], status.color[2]);
                        data.cell.styles.halign = 'center';
                        data.cell.text = this.$t(status.name);
                    }
                },
                didDrawCell: (data) => {
                    if (data.section === 'body' && data.column.index === 9) {
                        const status = data.cell.raw;
                        data.cell.styles.fillColor = [status.color[0], status.color[1], status.color[2]];
                        data.cell.styles.halign = 'center';
                    }
                }
                /* eslint-enable no-param-reassign */
            });
            this.addPagesToPdfExport(doc, pageCount);
            this.addFooters(doc);
            doc.save(`export-users_payrolls_${dateInString}.pdf`);
        },
        getMinute(minute) {
            return minute < this.defaultMarginSizeX ? `0${minute}` : minute;
        },
        isSettlement(transaction) {
            return transaction.status === 'PAYC_ADJNET' && transaction.paymentType === 'WALCADJ' && transaction.refid === '';
        },
        isHourAdjustment(transaction) {
            return transaction.status === 'PAYC_ADJHOUR' && transaction.paymentType === 'WALCADJ';
        }
    }
};
</script>
